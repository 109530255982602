import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { Layout, PostCard } from "../components/common";
import { MetaData } from "../components/common/meta";
import {
    FaDesktop,
    FaEnvelope,
    FaMobileAlt,
    FaFacebookF,
} from "react-icons/fa";

/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */
const Index = ({ data, location }) => {
    const posts = data.allGhostPost.edges;

    const secureFieldTestimonials = [{
        quote: `Oakley is soooo tired tonight,  I love seeing all the pictures. Thank you so much, you guys are amazing!`,
    },
    {
        quote: `Alfie was asleep for 4 hours after that walk, he must have had such a great time!`,
    },
    {
        quote: `You two really are one in a million, Nells had the best time!`,
    },
    {
        quote: `We are so happy to have found you both, the field is amazing'`,
    },
    ];

    const groupTestimonials = [{
        quote: `Thank you, ladies, Gus loves his walks (and swims!) with you`,
    },
    {
        quote: `Another group, another tired pooch! Makes me so happy`,
    },
    ];

    return (
        <>
            <MetaData location={location} title="Home" />
            <Layout isHome={true}>
                <div className="container">
                    <h1 className="title">Secure Field Pack Walks</h1>
                    <p>
                        This service is perfect for dogs that are friendly and well-mannered but that have limited recall or are unable to be let off the lead for whatever reason. Your dogs will be able to stretch their legs within a 4-acre field complete with a full agility set, running water,  a weather shelter and lots more all secured by hardy 8-foot fences. The service lasts for 90 minutes. Transport and wash down are included in the price.
                    </p>
                    <div className="testimonial">
                        <p>
                            Here is what our clients have to say:
                        </p>
                        <ul>
                            {secureFieldTestimonials.map(t => (
                                <li key={t.id} className="quoteMessage">
                                    <div className="message">
                                        <p>
                                            {t.quote}
                                        </p>
                                    </div>
                                    {t.by && <span className="quoteBy">
                                        {t.by}
                                    </span>}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <h1 className="title">Group Walks</h1>
                    <p>Perfect for dogs that love the woods or the park, these walks are 1 hour minimum and are suitable for dogs that have reliable recall and can be walked off lead. Group walks are out in the public so dogs must be able to be walked on the lead and off the lead without reactivity.  Transport and towel dry is included in the price. </p>
                    <div className="testimonial">
                        <p>
                            Here is what our clients have to say:
                        </p>
                        <ul>
                            {groupTestimonials.map(t => (
                                <li key={t.id} className="quoteMessage">
                                    <div className="message">
                                        <p>
                                            {t.quote}
                                        </p>
                                    </div>
                                    {t.by && <span className="quoteBy">
                                        {t.by}
                                    </span>}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <ul
                        style={{
                            textDecoration: `none`,
                            listStyleType: `none`,
                        }}
                    >
                        <li>
                            <Link className="site-nav-item" to="/contact">
                                <FaDesktop /> Contact Form
                            </Link>
                        </li>
                        <li>
                            <a className="site-nav-item" href="tel:07805263871">
                                <FaMobileAlt /> 07805263871
                            </a>
                        </li>
                        <li>
                            <a
                                className="site-nav-item"
                                href="https://facebook.com"
                            >
                                <FaFacebookF /> Facebook
                            </a>
                        </li>
                        <li>
                            <a
                                className="site-nav-item"
                                href="mailto:lauren@crawleydogwalkers.com"
                            >
                                <FaEnvelope /> Email
                            </a>
                        </li>
                    </ul>
                    <hr></hr>
                    <h1>Blog Posts</h1>
                    <section className="post-feed">
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </section>
                </div>
            </Layout>
        </>
    );
};

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default Index;

export const pageQuery = graphql`
    query LatestPostsAndImages {
        allGhostPost(sort: { order: DESC, fields: [published_at] }, limit: 3) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
